import { Suspense, lazy } from 'preact/compat';
import ErrorBoundary from '../lib/ErrorBoundary';
import { AnalyticsProvider } from '@nerdwallet/seo-analytics';

import StructuredContent, {
  StructuredContentProvider,
  createClient,
} from '@nerdwallet/structured-content-core';
import components from '../../structured-content/components';
import schema from '../../structured-content/schema';
import ApolloWrapper from '../auth/global/ApolloWrapper';

type Props = {
  content: any;
};

const structuredContentClient = createClient({
  codesplittingFunction: (asyncImport: any, exportName: string) => {
    return lazy(async () => {
      const component = (await asyncImport)[exportName];
      return component;
    });
  },
  components,
  schema,
});

const PromoSpot: React.FC<Props> = ({ content }) => {
  return (
    <ErrorBoundary>
      <ApolloWrapper>
        <AnalyticsProvider>
          <StructuredContentProvider client={structuredContentClient}>
            <Suspense fallback={null}>
              <StructuredContent>{content}</StructuredContent>
            </Suspense>
          </StructuredContentProvider>
        </AnalyticsProvider>
      </ApolloWrapper>
    </ErrorBoundary>
  );
};

export default PromoSpot;
