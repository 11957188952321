import schema from '@nerdwallet/structured-content/schema';
import {
  BUTTON,
  CONTAINER,
  DOCUMENT,
  PROMOCARD,
} from '@nerdwallet/structured-content/types';

export default {
  [BUTTON]: schema[BUTTON],
  [CONTAINER]: schema[CONTAINER],
  [DOCUMENT]: schema[BUTTON],
  [PROMOCARD]: schema[PROMOCARD],
};
