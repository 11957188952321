import {
  BUTTON,
  CONTAINER,
  DOCUMENT,
  PROMOCARD,
} from '@nerdwallet/structured-content/types';
export default {
  // Foo: () => [import('./Foo')],
  [CONTAINER]: () => [import('@nerdwallet/react-container')],
  [DOCUMENT]: () => [
    import('@nerdwallet/structured-content/dist/components/document'),
  ],
  [BUTTON]: () => [
    import('@nerdwallet/structured-content/dist/components/button'),
  ],
  [PROMOCARD]: () => [
    import('@nerdwallet/structured-content/dist/components/promo-card'),
  ],
};
